import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

// Función para inicializar la escena
function initScene(containerId, modelPath, modelScale, hdrPath, MappingExposure, animateCallback, perspectiveOption, cameraScale = 1, offsetX = 0) {
  const container = document.getElementById(containerId);

  const renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setSize(container.clientWidth, container.clientHeight);
  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.toneMappingExposure = MappingExposure;
  renderer.setClearColor(0x00FF00, 0); // Color de fondo verde con transparencia
  container.appendChild(renderer.domElement);

  const scene = new THREE.Scene();

  const camera = new THREE.PerspectiveCamera(45, container.clientWidth / container.clientHeight, 0.1, 1000);
  
  let modelCenter = new THREE.Vector3(); // Variable para almacenar el centro del modelo

  function changeCameraPerspective(camera, option, scene, scale) {
    const boundingBox = new THREE.Box3().setFromObject(scene);
    const center = boundingBox.getCenter(new THREE.Vector3());

    switch (option) {
      case 'angle':
        camera.position.set(10 * scale, 10 * scale, 10 * scale); // Perspectiva en ángulo con escala
        camera.lookAt(center);
        break;
      case 'current':
        // Ajustar la cámara para enfocar el modelo
        if (model) {
          const boundingBox = new THREE.Box3().setFromObject(model);
          const size = boundingBox.getSize(new THREE.Vector3());
          const center = boundingBox.getCenter(new THREE.Vector3());

          // Calcular la posición de la cámara basada en el tamaño del modelo
          const distance = Math.max(size.x, size.y, size.z) * 2 * scale;
          camera.position.set(center.x + distance, center.y, center.z + distance);

          // Apuntar la cámara al centro del modelo
          camera.lookAt(center);

          // Asegurarse de que la cámara esté correctamente ajustada
          camera.updateProjectionMatrix();
        }
        break;
      case 'overview':
        const size = boundingBox.getSize(new THREE.Vector3());
        const distance = Math.max(size.x, size.y, size.z) * 3 * scale; // Más alejado para vista general con escala
        camera.position.set(center.x, center.y + distance, center.z + distance);
        camera.lookAt(center);
        break;
      default:
        console.error('Unknown perspective option:', option);
    }
  }

  const rgbeLoader = new RGBELoader();
  const gltfLoader = new GLTFLoader();

  let model;
  let mixer;

  rgbeLoader.load(hdrPath, function(texture) {
    texture.mapping = THREE.EquirectangularReflectionMapping;
    scene.environment = texture;

    gltfLoader.load(modelPath, function(gltf) {
      model = gltf.scene;
      model.scale.set(modelScale, modelScale, modelScale);
      model.position.x += offsetX;
      scene.add(model);
      mixer = new THREE.AnimationMixer(model);
      gltf.animations.forEach((clip) => {
        mixer.clipAction(clip).play();
      });

      // Calcular el centro del modelo
      const boundingBox = new THREE.Box3().setFromObject(model);
      modelCenter = boundingBox.getCenter(new THREE.Vector3());

      // Ajustar la cámara según la perspectiva deseada
      changeCameraPerspective(camera, perspectiveOption, scene, cameraScale);
    }, undefined, function(error) {
      console.error('Error loading GLTF model:', error);
    });
  }, undefined, function(error) {
    console.error('Error loading HDR map:', error);
  });

  function animate() {
    requestAnimationFrame(animate);
    if (mixer) {
      mixer.update(0.01);
    }
    if (animateCallback) {
      animateCallback(model);
    }
    renderer.render(scene, camera);
  }

  animate();

  function onResize() {
    camera.aspect = container.clientWidth / container.clientHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(container.clientWidth, container.clientHeight);
  }

  window.addEventListener('resize', onResize);
}

// Función de animación personalizada con órbitas diferenciadas
function animateModel(model) {
  if (model) {
    const objectNames = [];
    model.traverse((child) => {
      if (child.isMesh) {
        objectNames.push(child.name);
      }
    });

    model.traverse((child) => {
      if (child.isMesh) {
        const index = objectNames.indexOf(child.name);
        const currentTime = Date.now() * 0.01; // Convertir el tiempo a segundos

        // Parámetros de órbita específicos para cada rango de índices
        let radius, speed, angleOffset, angleDirection;

        switch (true) {
          case (index >= 0 && index <= 3):
            radius = 5;
            speed = 0.1;
            angleOffset = 0;
            angleDirection = 1; // Dirección de órbita positiva
            break;
          case (index >= 4 && index <= 7):
            radius = 10;
            speed = 0.1;
            angleOffset = Math.PI / 4;
            angleDirection = -1; // Dirección de órbita negativa
            break;
          case (index >= 8 && index <= 15):
            radius = 14;
            speed = 0.1;
            angleOffset = Math.PI / 2;
            angleDirection = 1; // Dirección de órbita positiva
            break;
          case (index >= 16 && index <= 21):
            radius = 12;
            speed = 0.1;
            angleOffset = Math.PI;
            angleDirection = -1; // Dirección de órbita negativa
            break;
          case (index >= 22 && index <= 24):
            radius = 14;
            speed = 0.1;
            angleOffset = 3 * Math.PI;
            angleDirection = 1; // Dirección de órbita positiva
            break;
          default:
            radius = 2;
            speed = 1;
            angleOffset = 2;
            angleDirection = -1; // Dirección de órbita negativa
            break;
        }

        if (radius > 0) {
          const angle = speed * currentTime + angleOffset;
          child.position.x = radius * Math.cos(angle * angleDirection);
          child.position.y = radius * Math.sin(angle * angleDirection);
          child.rotation.z += 0.01;
        }
      }
    });
  }
}

// Inicializar las escenas con diferentes perspectivas, escalas de cámara y separación en el eje X
// initScene('Banner', './assets/planet_collection/scene.gltf', 3, './assets/HDR/NbB.hdr', 1, animateModel, 'current', 1, 0);
initScene('Headset', './assets/Quest3/Quest3.gltf', 15, './assets/HDR/BWS.hdr',0.4, function(model) {
  if (model) {
    model.rotation.y += 0.01;
  }
}, 'current', 0.6, 1);
